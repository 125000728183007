import { useContext } from "react";
import {
  useDaily,
  useLocalSessionId,
  useParticipantIds,
  useParticipantProperty,
  useScreenShare,
} from "@daily-co/daily-react";
import { runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import { loggerContext, StoreContext } from "@/stores";
import { initLogger } from "@/utils/logging.utils";
import MeetingFooter from "../layout/controls/MeetingFooter";

const logger = initLogger("DailyMeetingFooter", loggerContext);

const DailyMeetingFooter = () => {
  const {
    meetingStore,
    participantStore,
    userStore: { isStaff },
  } = useContext(StoreContext);
  const { remoteParticipants } = participantStore;

  const dailyCall = useDaily();
  const localParticipantId = useLocalSessionId();
  const localTracks = useParticipantProperty(localParticipantId, "tracks");
  const allParticipantIds = useParticipantIds();

  const { isSharingScreen, startScreenShare, stopScreenShare } = useScreenShare({
    onError: e =>
      logger.postEvent(
        "Warning",
        "error sharing screen",
        { localParticipantId, error: e.errorMsg },
        { feature: "Screenshare" },
      ),
    onLocalScreenShareStarted: () =>
      logger.postEvent("Success", "started screen share", { localParticipantId }, { feature: "Screenshare" }),
    onLocalScreenShareStopped: () =>
      logger.postEvent("Success", "stopped screen share", { localParticipantId }, { feature: "Screenshare" }),
  });

  const isLocalAudioEnabled = localTracks?.audio.state === "playable";
  const isLocalVideoEnabled = localTracks?.video.state === "playable";

  const activeParticipantKeys = remoteParticipants
    .filter(p => p.dailySessionId && allParticipantIds.includes(p.dailySessionId))
    .map(p => p.key);

  const toggleVideo = () => {
    const isHiddenUpdate = isLocalVideoEnabled;
    dailyCall?.setLocalVideo(!isHiddenUpdate);
    participantStore.updateLocalParticipant(
      { isVideoHidden: isHiddenUpdate },
      isHiddenUpdate ? "videoOff" : "videoOn",
      { skipSync: true },
    );
  };
  const toggleAudio = () => {
    const isMutedUpdate = isLocalAudioEnabled;
    dailyCall?.setLocalAudio(!isMutedUpdate);
    participantStore.updateLocalParticipant({ isAudioMuted: isMutedUpdate }, isMutedUpdate ? "audioOff" : "audioOn", {
      skipSync: true,
    });
  };

  const disconnect = async () => {
    if (!!meetingStore.inProgressRecording && isStaff) meetingStore.toggleSessionRecording();
    await dailyCall?.leave();
    runInAction(() => {
      meetingStore.connectedMeetingKey = undefined;
      meetingStore.connectedParticipantKey = undefined;
    });
  };

  return (
    <MeetingFooter
      video={{ isEnabled: isLocalVideoEnabled, toggle: toggleVideo }}
      audio={{ isEnabled: isLocalAudioEnabled, toggle: toggleAudio }}
      screenshare={{
        isEnabled: isSharingScreen,
        toggle: () => (isSharingScreen ? stopScreenShare() : startScreenShare()),
      }}
      disconnect={disconnect}
      activeParticipantKeys={activeParticipantKeys}
    />
  );
};

export default observer(DailyMeetingFooter);
