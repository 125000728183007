import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, useLocation, useNavigationType, createRoutesFromChildren, matchRoutes } from "react-router";
import { DailyProvider } from "@daily-co/daily-react";
import { ThemeProvider } from "@mui/material/styles";
import { GoogleOAuthProvider } from "@react-oauth/google";
import * as Sentry from "@sentry/react";
import { AxiosError } from "axios";
import { defaultTheme } from "@parallel/polygon/util/style.util";
import config from "@/config";
import App from "./App";
import "./main.css";

const isProd = config.parallelEnv === "production";

if (config.parallelEnv !== "local") {
  Sentry.init({
    dsn: config.sentryEndpoint,
    environment: config.parallelEnv,
    integrations: [
      Sentry.browserProfilingIntegration(),
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      Sentry.replayIntegration({
        maskAllText: isProd,
        blockAllMedia: isProd,
      }),
      Sentry.thirdPartyErrorFilterIntegration({
        filterKeys: ["telehealth-sentry-application-key"],
        behaviour: "apply-tag-if-contains-third-party-frames",
      }),
    ],
    profilesSampleRate: isProd ? 0.1 : 1.0,
    replaysOnErrorSampleRate: isProd ? 0.1 : 1.0,
    replaysSessionSampleRate: isProd ? 0.1 : 1.0,
    tracesSampleRate: isProd ? 0.1 : 1.0,
    beforeSend: (event, hint) => {
      let warningType = "";
      if (hint.originalException instanceof AxiosError) {
        warningType = "axios";
      } else if (
        event.message?.match(
          /Non-Error promise rejection captured with value: Object Not Found Matching Id(.*), MethodName:(.*), ParamCount:(.*)/,
        )
      ) {
        warningType = "object-not-found";
      }
      if (warningType) {
        event.tags = { ...event.tags, warningType };
        event.level = "warning";
      }
      return event;
    },
  });
}

function FallbackComponent() {
  return <div>An error has occurred</div>;
}

(async () => {
  const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
  root.render(
    <React.StrictMode>
      <Sentry.ErrorBoundary fallback={<FallbackComponent />} showDialog>
        <GoogleOAuthProvider clientId={config.oauthClientId}>
          <DailyProvider>
            <BrowserRouter>
              <ThemeProvider theme={defaultTheme}>
                <App />
              </ThemeProvider>
            </BrowserRouter>
          </DailyProvider>
        </GoogleOAuthProvider>
      </Sentry.ErrorBoundary>
    </React.StrictMode>,
  );
})();
