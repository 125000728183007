import { useContext, useEffect, useState } from "react";
import { MoonLoader } from "react-spinners";
import { IonIcon } from "@ionic/react";
import { useAsync } from "@react-hookz/web";
import { DateTime } from "luxon";
import { observer } from "mobx-react-lite";
import { ExtendedAppointment } from "@parallel/vertex/types/calendar/appointment.types";
import { isAuthError } from "@parallel/vertex/util/nexus.base.api";
import BlockingErrorLayout from "@/components/common/content/error/BlockingErrorLayout";
import { GenericNetworkHelpContent } from "@/components/common/content/error/ConnectionHelpContent";
import Link from "@/components/common/elements/Link";
import uTurnLeft from "@/icons/uturn-left-ocean.svg";
import { StoreContext, loggerContext } from "@/stores";
import { initLogger, useEventRedirect } from "@/utils/logging.utils";
import AppointmentDisplay from "../common/content/AppointmentBanner";
import LobbyCommon from "./LobbyCommon";

const logger = initLogger("UpcomingAppointmentsLobby", loggerContext);

const UpcomingAppointmentsLobby = ({ loadingText }: { loadingText?: string }) => {
  const {
    apiStore: { appointmentApi },
    userStore,
    meetingStore,
    alertStore,
  } = useContext(StoreContext);
  const redirect = useEventRedirect(logger);

  const [loadError, setLoadError] = useState("");
  const [{ result: appointments }, { execute: fetchAppointments }] = useAsync(async () => {
    const { signedInUserId, isClient } = userStore;
    if (!signedInUserId) {
      redirect("/login", "upcoming appointment lobby requires sign in");
      return null;
    }
    return appointmentApi
      .searchAppointments({ pageSize: 4, startTime: DateTime.utc().minus({ minutes: 30 }) })
      .then(page => page.records)
      .then(logger.operationSuccessHandler("getAppointments"))
      .catch(e => {
        logger.postEvent("Error", "upcoming appointment lobby data load failed", { signedInUserId, isClient });
        setLoadError("There was an unexpected error fetching your appointments");
        // if unable to fetch due to auth error, set `shouldReloadOnLogin` flag so page reloads on sign in
        if (isAuthError(e)) alertStore.setShouldReloadOnLogin(true);
      });
  });

  useEffect(() => {
    fetchAppointments();
  }, [userStore.userId]);

  const showAppointment = async (appointment: ExtendedAppointment) => {
    let signedInClient;
    if (userStore.isClient) {
      signedInClient = appointment.students.find(s => s.userId === userStore.userId);
      if (!signedInClient) {
        logger.reportIssue(
          `invalid group therapy appointment ${appointment.appointmentId} displayed to user ${userStore.userId}`,
          { appointment },
        );
        alertStore.push("Appointment access blocked", { resolution: "access" });
        return;
      }
    }
    meetingStore.setAppointment(appointment);

    const locationUrlPart = userStore.isClient ? "waiting-room" : "meeting";
    redirect(`/${locationUrlPart}/appointment/${appointment.appointmentId}/v2`, "meeting join");
  };

  const headerText =
    !!appointments && appointments.length === 0
      ? "You have no upcoming appointments scheduled"
      : "Select an appointment";

  let content = (
    <div className="flex flex-row items-center gap-4">
      <MoonLoader size="12px" />
      <p>Loading</p>
    </div>
  );
  if (loadError) {
    content = (
      <BlockingErrorLayout
        header="Appointment Fetch Error"
        details={<GenericNetworkHelpContent errorDescription={loadError} issueTitle="Appointment Fetch Error" />}
      />
    );
  } else if (appointments) {
    content = (
      <>
        {appointments.slice(0, 3).map((a, i) => (
          <AppointmentDisplay
            appointment={a}
            onClick={() => showAppointment(a)}
            key={`${a.appointmentId}-${i}`}
            isUpcomingAppointmentLink={true}
          />
        ))}
      </>
    );
  }

  if (!loadingText && !appointments) loadingText = "Loading Appointments";

  const isPageReady = !!appointments && !loadingText && !loadError;
  useEffect(() => {
    if (!isPageReady) return;
    logger.postEvent("PageReady", "upcoming appointments lobby ready", { appointments });
  }, [isPageReady]);

  return (
    <LobbyCommon appointmentHeaderText={headerText} isError={!!loadError} loadingText={loadingText}>
      <div className="flex flex-col gap-4">{content}</div>
      <div className="pt-8 text-ocean gap-2 flex">
        <span className="text-black">Not your appointments?</span>{" "}
        <Link
          className="text-navy italic flex justify-center items-center gap-1 hover:cursor-pointer hover:font-bold"
          onClick={async () => {
            await userStore.signOut();
            redirect("/login", "lobby logout");
          }}
        >
          <IonIcon icon={uTurnLeft} /> Back to Login
        </Link>
      </div>
    </LobbyCommon>
  );
};

export default observer(UpcomingAppointmentsLobby);
