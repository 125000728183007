import { DailyCall } from "@daily-co/daily-js";
import { makeAutoObservable, runInAction } from "mobx";
import { makePersistable } from "mobx-persist-store";
import { v4 as uuid } from "uuid";
import { ExtendedAppointment } from "@parallel/vertex/types/calendar/appointment.types";
import { MeetingDisplay, AppointmentMeetingFlags } from "@parallel/vertex/types/meeting.types";
import { Notification } from "@parallel/vertex/types/notification.types";
import { AgoraRecording } from "@parallel/vertex/types/recording.types";
import { mapExists } from "@parallel/vertex/util/collection.util";
import { getInitials } from "@parallel/vertex/util/string.util";
import { MeetingAPI } from "@/api/meeting.api";
import { RecordingAPI } from "@/api/recording.api";
import config from "@/config";
import { durationMinutes } from "@/utils/appointment";
import { documentCameraTrackId } from "@/utils/daily.utils";
import { TelehealthLogger } from "@/utils/logging.utils";
import { AlertStore } from "./alert.store";
import { UserStore } from "./user.store";

export type LeftSidebarType = "assessments" | "chat" | "client-goals" | "activities";

export type RightSidebarType = "participants" | "settings" | "help";

type MeetingDisplayWriteOperation =
  | "setDocumentCamera"
  | "setPinnedParticipant"
  | "providerSelfViewOn"
  | "providerSelfViewOff"
  | "clientSelfViewOn"
  | "clientSelfViewOff"
  | "notificationCreated"
  | "notificationRead";

export class MeetingStore {
  connectedMeetingKey?: string = undefined;
  connectedParticipantKey?: string = undefined;
  connectedDailySessionId?: string = undefined;

  appointment?: ExtendedAppointment = undefined;
  appointmentFlags?: AppointmentMeetingFlags = undefined;

  // UX
  showSettings = false;
  isDraggingNotes = false;
  leftSidebarType?: LeftSidebarType = undefined;
  rightSidebarType?: RightSidebarType = undefined;
  isSelectingGame = false;
  isStartingSessionRecording = false;
  isManagingParticipants = false;
  isProviderScreenshareMinimized = false;

  displayState?: MeetingDisplay = undefined;
  localStateUpdateId?: string;

  unreadMessageCount: number = 0;

  inProgressRecording?: AgoraRecording = undefined;

  localDocumentCameraTrackId?: string = undefined;
  remoteDocumentCamera?: { track: MediaStreamTrack; id: string; participantKey: string; displayName: string };

  isLiveCaptioningEnabled: boolean = false;
  providerTranscript: string = "";

  triggerRemoteSync?: (operation: MeetingDisplayWriteOperation, logParams?: any) => unknown = undefined;

  emitProviderClosedChat: () => unknown = () => this.logger.warn("provider closed chat emitter not defined");

  constructor(
    private userStore: UserStore,
    private alertStore: AlertStore,
    private meetingApi: MeetingAPI,
    private recordingApi: RecordingAPI,
    public logger: TelehealthLogger,
  ) {
    makeAutoObservable(this);
    makePersistable(this, {
      name: "MeetingStore",
      properties: ["isLiveCaptioningEnabled"],
      storage: window.localStorage,
    });
  }

  get hasNotifications() {
    return !!this.notification;
  }

  get notification() {
    if (!this.userStore.userId) return;
    if (!this.displayState?.notifications) return;

    return this.displayState.notifications.find(
      (n: Notification) => !!n && this.userStore.userId && n.deliverToUserIds?.includes(this.userStore.userId),
    );
  }

  get hasLoadedState() {
    return !!this.displayState;
  }

  get meetingName() {
    return this.appointment?.title;
  }

  get durationMinutes() {
    if (!this.appointment) return;
    return durationMinutes(this.appointment);
  }

  get otherParticipantString() {
    if (this.userStore.isClient) return this.appointment?.provider.fullName;
    return this.clientFullNamesList?.join(", ");
  }

  get providerUserId() {
    return this.appointment?.provider?.userId;
  }

  get providerFullName() {
    return this.appointment?.provider?.fullName;
  }

  get feedbackFormTemplateId() {
    const formTemplateId = this.appointment?.appointmentType.formTemplateId;
    return formTemplateId && config.feebackFormTemplateIds.includes(formTemplateId) ? formTemplateId : undefined;
  }

  get isGroupAppointment() {
    return !!this.appointment && this.appointment.students.length > 1;
  }

  get isTestingSession() {
    return this.appointment && this.appointment.appointmentTypeId === config.testingSessionAppointmentTypeId;
  }

  get isClientSelfViewEnabled() {
    return this.displayState?.isClientSelfViewEnabled || false;
  }

  get isProviderSelfViewDisabled() {
    return this.displayState?.isProviderSelfViewDisabled || false;
  }

  get activeDocumentCamera() {
    const activeId = this.displayState?.activeDocumentCameraId;
    if (activeId && this.remoteDocumentCamera && activeId === this.remoteDocumentCamera.id) {
      return this.remoteDocumentCamera;
    }
  }

  get pinnedParticipantKey() {
    return this.displayState?.pinnedParticipantKey;
  }

  get isAssessmentSidebarEnabled() {
    return this.leftSidebarType === "assessments";
  }

  get isChatSidebarEnabled() {
    return this.leftSidebarType === "chat";
  }

  get isClientGoalSidebarEnabled() {
    return this.leftSidebarType === "client-goals";
  }

  get clientFullNamesList() {
    if (!this.appointment) return [];
    return mapExists(this.appointment?.students, s => s.fullName);
  }

  get attendeeNames(): Record<string, { fullName: string; initials: string }> {
    if (!this.appointment) return {};
    return [this.appointment.provider, ...this.appointment.students].reduce(
      (names, nextUser) => ({
        ...names,
        [nextUser.userId]: { fullName: nextUser.fullName, initials: getInitials(nextUser) },
      }),
      {} as Record<string, { fullName: string; initials: string }>,
    );
  }

  // get the first and last name initials for use with participant avatars
  attendeeInitials(firstName: string, lastName: string): string {
    return `${firstName[0]}${lastName[0]}`;
  }

  markNotificationRead(notificationId: string) {
    if (!this.displayState?.notifications) return;

    const notification = this.displayState?.notifications?.find((n: Notification) => n.id === notificationId);
    if (notification) {
      notification.deliverToUserIds = notification.deliverToUserIds.filter((u: string) => u !== this.userStore.userId);
    }

    this.updateDisplayState({ notifications: this.displayState?.notifications }, "notificationRead");
  }

  setAppointment(appointment: ExtendedAppointment) {
    this.appointment = appointment;
  }

  setProviderScreenshareMinimize(isProviderScreenshareMinimized: boolean) {
    this.isProviderScreenshareMinimized = isProviderScreenshareMinimized;
  }

  toggleShowSettings() {
    this.showSettings = !this.showSettings;
  }

  setLeftSidebar = (type: LeftSidebarType | undefined) => {
    if (this.isChatSidebarEnabled && !type && this.userStore.isStaff) this.emitProviderClosedChat();
    if (type === "chat") this.unreadMessageCount = 0;
    this.leftSidebarType = type;
  };

  setRightSidebar = (type: RightSidebarType | undefined) => {
    this.rightSidebarType = type;
  };

  toggleRightSideBar(contentType: RightSidebarType) {
    this.rightSidebarType === contentType ? this.setRightSidebar(undefined) : this.setRightSidebar(contentType);
  }

  toggleLeftSidebar(contentType: LeftSidebarType) {
    this.leftSidebarType === contentType ? this.setLeftSidebar(undefined) : this.setLeftSidebar(contentType);
  }

  clearLeftSidebar(contentType?: LeftSidebarType) {
    if (!contentType || this.leftSidebarType === contentType) {
      this.setLeftSidebar(undefined);
    }
  }

  clearRightSidebar() {
    this.setRightSidebar(undefined);
  }

  incrementUnreadMessageCount() {
    this.unreadMessageCount += 1;
  }

  toggleProviderScreenshareMinimized() {
    this.setProviderScreenshareMinimize(!this.isProviderScreenshareMinimized);
  }

  toggleSelectingGame() {
    this.isSelectingGame = !this.isSelectingGame;
  }

  toggleManagingParticipants() {
    this.isManagingParticipants = !this.isManagingParticipants;
  }

  toggleSessionRecordingModal() {
    this.isStartingSessionRecording = !this.isStartingSessionRecording;
  }

  toggleLiveCaptioning(state: boolean | undefined) {
    this.isLiveCaptioningEnabled = state ?? !this.isLiveCaptioningEnabled;
  }

  createNotification(notification: Omit<Notification, "id">) {
    const decoratedNotification: Notification = {
      ...notification,
      id: uuid(),
    };
    this.updateDisplayState(
      { notifications: [decoratedNotification, ...(this.displayState?.notifications || [])] },
      "notificationCreated",
    );
  }

  sync = async (meetingKey: string, dailySessionId: string, { operation = "unknown", logParams, syncId }: any) => {
    const { display } = await this.meetingApi.getMeetingState(meetingKey);
    runInAction(() => (this.displayState = display || undefined));

    this.logger.info(`received ${operation} display state sync from daily session ${dailySessionId}`, {
      operation,
      logParams,
      display,
      syncId,
    });
  };

  updateDisplayState = async (
    request: Partial<MeetingDisplay>,
    operation: MeetingDisplayWriteOperation,
    { skipSync, logParams }: { skipSync?: boolean; logParams?: any } = {},
  ) => {
    try {
      if (!this.connectedMeetingKey) return;

      const updatedLocal = { ...this.displayState, ...request };
      const { display: updatedRemote } = await this.meetingApi.updateMeetingDisplay(
        this.connectedMeetingKey,
        updatedLocal,
        this.userStore.userId,
      );
      this.displayState = updatedRemote || undefined;

      if (!skipSync) {
        if (!this.triggerRemoteSync) throw new Error("display state update remote trigger not set");
        this.triggerRemoteSync(operation, { ...logParams, request });
      }

      this.logger.info(
        `triggered ${operation} display state ${skipSync ? "write" : "sync"} from daily session ${this.connectedDailySessionId}`,
        {
          operation,
          logParams,
          request,
          updatedLocal,
          updatedRemote,
          skipSync,
        },
      );
    } catch (e: any) {
      this.logger.error(
        `error triggering ${operation} display state ${skipSync ? "write" : "sync"} from daily session ${this.connectedDailySessionId}`,
        {
          operation,
          logParams,
          error: e.message,
          request,
        },
      );
    }
  };

  /**
   * toggle agora webrecorder to start/stop session recording
   */
  async toggleSessionRecording() {
    this.isStartingSessionRecording = false;

    const userId = this.userStore.userId;
    if (!this.appointmentFlags?.canRecordSession || !this.connectedMeetingKey || !userId) return;

    if (!this.inProgressRecording) {
      await this.recordingApi
        .startRecording(this.connectedMeetingKey, userId, "appointment")
        .then(this.logger.operationSuccessHandler("startRecording"))
        .then(recording => {
          runInAction(() => (this.inProgressRecording = recording));
        })
        .catch(e => {
          this.logger.operationError(
            "startRecording",
            { meetingKey: this.connectedMeetingKey, userId, location: "appointment" },
            e,
          );
          this.alertStore.push("Error starting session recording", { resolution: "retry" });
        });
    } else {
      await this.recordingApi
        .stopRecording(this.connectedMeetingKey, userId, this.inProgressRecording)
        .then(this.logger.operationSuccessHandler("stopRecording"))
        .then(() => {
          runInAction(() => (this.inProgressRecording = undefined));
        })
        .catch(e => {
          this.logger.operationError("stopRecording", e);
        });
    }
  }

  async stopDocumentCameraTrack(dailyCall: DailyCall) {
    if (!this.localDocumentCameraTrackId) return;
    await dailyCall.stopCustomTrack(this.localDocumentCameraTrackId);
    runInAction(() => {
      this.localDocumentCameraTrackId = undefined;
      this.remoteDocumentCamera = undefined;
    });
  }

  async startDocumentCameraTrack(dailyCall: DailyCall, deviceId: string) {
    this.stopDocumentCameraTrack(dailyCall);
    const media = await navigator.mediaDevices.getUserMedia({ video: { deviceId: { exact: deviceId } } });
    const track = media.getVideoTracks().find(t => t.getSettings().deviceId === deviceId);
    if (!track) return;
    const trackId = await dailyCall.startCustomTrack({
      track,
      trackName: documentCameraTrackId(deviceId),
    });
    runInAction(() => (this.localDocumentCameraTrackId = trackId));
  }

  setProviderTranscript(transcript: string) {
    this.providerTranscript = transcript;
  }
}
